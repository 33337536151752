import * as React from "react";
import { Layout, Menu, Breadcrumb } from "antd";
const { Header, Content, Footer } = Layout;
import "./whats-next.scss";
import "../styles/styles.scss"

const WhatsNextPage = () => {
  return (
    <Layout className="layout_2 whats-next" id="whats-next" style={{ padding: "50px 15%" }}>
      <div className="header">what's next?</div>
      <div className="whats-next-text">
        Feel free to reach out to me if you have an exciting idea, feedback or would like to talk about just anything. I'm always happy to chat <div className="happy"></div>       </div>
      <div className="intro__contact1">
        <span>Get in touch </span>
        <span className="emoji pointer">&nbsp;</span>
        <span>
          <a
            href="mailto:devanshagarwal50@gmail.com"

          >
            <div className="highlight-link"
            >devanshagarwal50@gmail.com</div>
          </a>
        </span>
      </div>
    </Layout>
  );
};

export default WhatsNextPage;
